import { Injectable, signal } from '@angular/core';
import { ISnackbar } from '@app/shared/model/snackbar.model';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  snackbarData = signal<ISnackbar>(null);
  private _timeoutRef: any = 0;

  show(data: ISnackbar) {
    clearTimeout(this._timeoutRef);
    this.snackbarData.set(null);
    setTimeout(() => {
      this.snackbarData.set(data);
    }, 150);
    this._hide();
  }

  private _hide() {
    this._timeoutRef = setTimeout(() => {
      this.snackbarData.set(null);
    }, 5000);
  }
}
