import { inject, Injectable } from '@angular/core';
import { IApiResponse, ICredentials, ILoginResponse } from '@app/core/models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments';
import { catchError, map, tap } from 'rxjs/operators';
import { LocalStorageService } from '@app/core/services';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { PermissionService } from '@app/core/services/permission.service';
import { IChange2FAMethod } from '@app/views/cpl/views/profile/models/change-2fa-method.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _authBaseUrl = `${environment.api.url}/admin/accounts`;
  private readonly _securityUrl = `${environment.api.url}/admin/security`;

  private _permissionService = inject(PermissionService);
  private _httpClient = inject(HttpClient);
  private _localStorage = inject(LocalStorageService);
  private _router = inject(Router);

  private accessToken: string | null = null;
  private accessTokenFlag = 'accessToken';

  private refreshToken: string | null = null;
  private refreshTokenFlag = 'refreshToken';
  private clientIdHeader = new HttpHeaders({
    clientId: 'cpl-client',
    'ngrok-skip-browser-warning': '69420',
  });

  private _userId: string | null = null;
  private _userIdFlag = 'userId';

  private _permissionFlag = 'PERMISSIONS';

  login(credentials: ICredentials) {
    return this._httpClient
      .post<IApiResponse<ILoginResponse>>(
        `${this._authBaseUrl}/login`,
        {
          context: '',
          data: credentials,
        },
        { headers: this.clientIdHeader },
      )
      .pipe(
        map((res) => {
          if (res.data) {
            this.setToken(res.data.accessToken, res.data.refreshToken);
            return res.data;
          } else
            throw Error(`Data didn't match the expected interface`, {
              cause: res,
            });
        }),
        catchError((err) => {
          throw Error(`Error on sign in request for ${credentials}`, err);
        }),
      );
  }

  sendCode(identifier: string): Observable<IChange2FAMethod> {
    return this._httpClient
      .post<IApiResponse<IChange2FAMethod>>(
        `${this._securityUrl}/send-code-with-identifier`,
        {
          data: {
            action: 0,
            identifier,
            identifierType: 1,
          },
        },
      )
      .pipe(map((res) => res.data));
  }

  verifyOtp({
    identifier,
    verifyCode,
    hashCode,
  }: {
    identifier: string;
    verifyCode: string;
    hashCode: string;
  }): Observable<{ hashCode: string }> {
    return this._httpClient
      .post<IApiResponse<{ hashCode: string }>>(
        `${this._securityUrl}/verify-code-with-identifier`,
        {
          data: {
            action: 0,
            identifierType: 1,
            identifier,
            verifyCode,
            hashCode,
          },
        },
      )
      .pipe(map((res) => res.data));
  }

  complete2FALogin({
    userName,
    password,
    identifier,
    hashCode,
  }: {
    userName: string;
    password: string;
    identifier: string;
    hashCode: string;
  }) {
    return this._httpClient
      .post<IApiResponse<ILoginResponse>>(
        `${this._authBaseUrl}/login/2fa`,
        {
          data: {
            userName,
            password,
            identifier,
            hashCode,
          },
        },
        { headers: this.clientIdHeader },
      )
      .pipe(
        map((res) => {
          if (res.data) {
            this.setToken(res.data.accessToken, res.data.refreshToken);
            return res.data;
          } else
            throw Error(`Data didn't match the expected interface`, {
              cause: res,
            });
        }),
      );
  }

  refreshAccessToken(): Observable<string | null> {
    return this._httpClient
      .post<IApiResponse<ILoginResponse>>(
        `${this._authBaseUrl}/refresh-token`,
        {
          data: { refreshToken: this.refreshToken },
        },
        { headers: this.clientIdHeader },
      )
      .pipe(
        map((res) => {
          if (res.data) {
            this.setToken(res.data.accessToken, res.data.refreshToken);
            return res.data.accessToken;
          } else {
            return null;
          }
        }),
      );
  }

  setToken(aToken: string | null, rToken?: string | null): void {
    if (aToken) {
      this._userId = jwtDecode(aToken).sub;
      localStorage.setItem(this._userIdFlag, this._userId);
      this.accessToken = aToken;
      this._localStorage.setItem(this.accessTokenFlag, aToken);
    }
    if (rToken) {
      if (aToken === null) {
        this._userId = jwtDecode(aToken);
        localStorage.setItem(this._userIdFlag, this._userId);
      }
      this.refreshToken = rToken;
      this._localStorage.setItem(this.refreshTokenFlag, rToken);
    }
  }

  clearToken() {
    this.accessToken = null;
    this._localStorage.removeItem(this.accessTokenFlag);

    this.refreshToken = null;
    this._localStorage.removeItem(this.refreshTokenFlag);

    this._userId = null;
    localStorage.removeItem(this._userIdFlag);

    localStorage.removeItem(this._permissionFlag);

    this._permissionService.clearUserPermissions();
  }

  getRefreshToken(): string | null {
    return this.refreshToken;
  }

  logout(): void {
    const subscription = new Subscription();
    subscription.add(
      this._httpClient
        .post<IApiResponse<ILoginResponse>>(`${this._authBaseUrl}/logout`, {})
        .pipe(
          tap(() => {
            this.clearToken();
            this._router.navigateByUrl('/auth');
            subscription.unsubscribe();
          }),
        )
        .subscribe(),
    );
  }

  logoutAfterPasswordChange() {
    this.clearToken();
    this._router.navigate(['/auth']);
  }
}
